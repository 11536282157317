<template>
  <div>
    <section class="welcome welcome__confirmedpay">
    </section>
    <div class="section container">
      <div class="welcome__inner confirmed__pay">
        <div class="form__container">
          <h3 class="confirmed__pay__title">Спасибо за пожертвование!</h3>
          <div class="confirmed__pay__text">
            <p>Благотворительный фонд помощи детям «МАВАДДА» выражает искреннюю благодарность за вашу помощь. </p>
            <p>
              Мы ценим любую поддержку, которую вы готовы оказать нам в нашей деятельности, будь то установка ящика для
              сбора
              пожертвований, материальная, финансовая, дельная, а также волонтерская и добровольная помощь, совместная
              работа по распространению и пропаганде гуманного отношения к детям и социально незащищенным слоям
              населения.
            </p>
            <p>Наш фонд является некоммерческой организацией, существующей на средства от благотворительных и
              добровольных
              пожертвований как физических, так и юридических лиц.</p>
          </div>
          <router-link :to="{ name: 'home' }" class="confirmed__pay__link">Вернуться на главную</router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_HOME_PAGE')
  },
  name: 'ConfirmedPay',
  metaInfo () {
    return this.$seo(
      'common',
      'Mavadda',
      'Mavadda',
      'Благотворительный фонд «МАВАДДА»',
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
}
</script>
